import React from 'react'

import "./CareProfessionalCard.css"
import IconDollar from "../SVG/IconDollar"
import IconPinSmall from "../SVG/IconPinSmall"
import IconStar from "../SVG/IconStar"
import IconService from "../SVG/IconService"
import IconSpeech from "../SVG/IconSpeech"

const CareProfessionalCard = ({ careProfessional }) => {

  const toKilometres = (distance) => {
    const km = distance / 1000;
    return `${km.toFixed(1)}km`;
  }

  return (
    <div
      className="CareProfessionalCard cursor-pointer"
    >
      <div className="flex flex-col md:flex-row items-center gap-2 md:gap-4 p-4">
        <div
          className="rounded-full border-2 border-pink-500 w-14 h-14 md:w-18 md:h-18 overflow-hidden"
        >
          <img
            className="w-full h-full object-cover"
            src={careProfessional.avatar}
            alt={careProfessional.name}
          />
        </div>
        <div
          className="flex-1 flex flex-col items-center md:items-start gap-3 md:gap-2"
        >
          <div
            className="w-full flex flex-col md:flex-row items-center justify-between gap-2 md:gap-4"
          >
            <h4 className="leading-snug font-semibold"> {careProfessional.name}</h4>
          </div>
          <div className="flex">


            <div className="flex items-center gap-2">
              <div
                className="text-sm text-grey-900 font-normal px-2.5 md:px-3 py-2 bg-pink-500 bg-opacity-10 rounded-full"
              >
                <div className="flex justify-center flex-wrap gap-2">
                  <div className="inline-flex items-center space-x-1">
                    <div className="w-3.5 h-3.5 flex-shrink-0">
                      <IconPinSmall />
                    </div>
                    <div className="leading-none">{toKilometres(careProfessional.distance)}</div>
                  </div>
                  <div className="inline-flex items-center space-x-1">
                    <div className="w-3.5 h-3.5 flex-shrink-0">
                      <IconStar />
                    </div>
                    <div className="leading-none">{careProfessional.rating}</div>
                  </div>
                  <div className="inline-flex items-center">
                    <div className="w-3.5 h-3.5 flex-shrink-0">
                      <IconDollar />
                    </div>
                    <div className="leading-none">{careProfessional.hourly_rate}/hr+</div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div >

      {(careProfessional.services.length) ?
        <div className="CareProfessionalCard__services">
          <div className="ListInline justify-center md:justify-start ListInline--flex ListInline--small">
            {
              careProfessional.services.map((service, index) =>
                <div className="ListItem ListItem--small" key={index}>
                  <div className="ListItem__icon inline-block">
                    <span><IconService /></span>
                  </div>
                  <span>{service}</span>
                </div>
              )
            }
          </div>
        </div> : null
      }

      {(careProfessional.languages.length) ?
        <div className="CareProfessionalCard__services">
          <div className="ListInline justify-center md:justify-start ListInline--flex ListInline--small">
            {
              careProfessional.languages.map(language =>
                <div className="ListItem ListItem--small">
                  <div className="ListItem__icon inline-block">
                    <span><IconSpeech /></span>
                  </div>
                  <span>{language}</span>
                </div>
              )
            }
          </div>
        </div> : null
      }

      <div className="CareProfessionalCard__foot pointer-events-none">
        <div className="gap-2 text-sm text-left">
          {careProfessional.about}
        </div>
      </div>
    </div >
  )
}

export default CareProfessionalCard
