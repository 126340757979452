import React from 'react'

const IconService = () => {
  return (
    <svg className='w-full h-full fill-current' viewBox='0 0 20 20'>
      <path d='M13.5 0H2v15.5h11.5V0zm-.7 14.8H2.7V.7h10.2v14.1zM18 4.5V20H6.5v-2.9c0-.2.1-.3.3-.3.2 0 .3.1.3.3v2.2h10.2V5.2h-2.2c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3H18zm-10.3 7c2.1 0 3.7-1.7 3.7-3.7S9.8 4 7.7 4 4 5.7 4 7.7s1.7 3.8 3.7 3.8zm0-6.8c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3-1.5-3-3.2 1.3-3 3-3zm-.3 3.1c0-.1 0-.1 0 0V5.5c0-.2.1-.3.3-.3.2 0 .3.1.3.3v1.7l1.1-.7c.2-.1.4 0 .4.1.1.2 0 .4-.1.4L7.9 8h-.2s-.1 0-.1-.1c-.2 0-.2-.1-.2-.1z' />
    </svg>
  )
}

export default IconService
