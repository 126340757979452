import React from 'react'

const IconSpeech = () => {
  return (
    <svg viewBox="0 -24 480 480">
      <path d="M408 0H72C32.254.043.043 32.254 0 72v208c.043 39.746 32.254 71.957 72 72h22.238L80.16 422.398c-.605 3.028.586 6.13 3.055 7.977s5.781 2.11 8.512.672L241.977 352H408c39.746-.043 71.957-32.254 72-72V72c-.043-39.746-32.254-71.957-72-72zm56 280c-.035 30.914-25.086 55.965-56 56H240c-1.3 0-2.578.316-3.727.922L99.145 409.098l12.695-63.496A7.997 7.997 0 0 0 104 336H72c-30.914-.035-55.965-25.086-56-56V72c.035-30.914 25.086-55.965 56-56h336c30.914.035 55.965 25.086 56 56zm0 0" />
    </svg>
  )
}

export default IconSpeech
