import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import SearchResults from "../components/SearchResults/SearchResults"
import FlexibleContent from "../components/FlexibleContent"

const IndexPage = ({ data, location }) => {
  const {
    body,
    theme,
    metaTitle,
    metaDescription,
    headingPrimary,
    flexibleContent,
  } = data.craftSearchResultsSearchResultsEntry

  const { linkSecondary } = data.craftGeneralGlobalSet

  return (
    <Layout
      title={metaTitle}
      description={metaDescription}
      theme={theme}
      location={location}
    >
      <div className="js-transition-link-target" data-theme={theme}>
        <SearchResults
          body={body}
          headingPrimary={headingPrimary}
          linkSecondary={linkSecondary}
        ></SearchResults>
        <FlexibleContent flexibleContent={flexibleContent} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    craftSearchResultsSearchResultsEntry {
      body
      theme
      metaTitle
      metaDescription
      headingPrimary
      flexibleContent {
        ...flexibleContentFragment
      }
    }
    craftGeneralGlobalSet {
      linkPrimary
      linkSecondary
    }
  }
`
