import React from 'react'
import PropTypes from "prop-types"

import "./CareProfessionalCard.css"
import IconDollar from "../SVG/IconDollar"
import IconPinSmall from "../SVG/IconPinSmall"
import IconStar from "../SVG/IconStar"

const CareProfessionalCardInfoWindow = ({ careProfessional }) => {

  const toKilometres = (distance) => {
    const km = distance / 1000;
    return `${km.toFixed(1)}km`;
  }

  return (
    <div
      className="CardProfile cursor-pointer"
    >
      <div className="flex flex-col items-center space-y-2 p-3">
        <div
          className="rounded-full border-2 border-pink-500 w-14 h-14 overflow-hidden"
        >
          <img
            className="w-full h-full object-cover"
            src={careProfessional.avatar}
            alt={careProfessional.name}
          />
        </div>
        <h4 className="text-sm">{careProfessional.name}</h4>

        <div className="flex items-center gap-2">
          <div
            className="text-sm text-grey-900 font-normal px-2.5 md:px-3 py-2 bg-pink-500 bg-opacity-10 rounded-full"
          >
            <div className="flex justify-center flex-wrap gap-2">
              <div className="inline-flex items-center space-x-1">
                <div className="w-3.5 h-3.5 flex-shrink-0">
                  <IconPinSmall />
                </div>
                <div className="leading-none">{toKilometres(careProfessional.distance)}</div>
              </div>
              <div className="inline-flex items-center space-x-1">
                <div className="w-3.5 h-3.5 flex-shrink-0">
                  <IconStar />
                </div>
                <div className="leading-none">{careProfessional.rating}</div>
              </div>
              <div className="inline-flex items-center">
                <div className="w-3.5 h-3.5 flex-shrink-0">
                  <IconDollar />
                </div>
                <div className="leading-none">{careProfessional.hourly_rate}/hr+</div>
              </div>
            </div>
          </div>
        </div>

      </div >
    </div >
  )
}

CareProfessionalCardInfoWindow.propTypes = {
  careProfessional: PropTypes.object.isRequired,
}

export default CareProfessionalCardInfoWindow
