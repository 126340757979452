import React from 'react'
import "./CareProfessionalCard.css"

const CareProfessionalCardSkeletonLoader = ({ ratio }) => {
  return (
    <div
      className="CareProfessionalCardSkeletonLoader"
      style={{ "--ratio": `calc(${ratio[0]} / ${ratio[1]} * 100%)` }}
    >
      <div className="CareProfessionalCardSkeletonLoader_inner"></div>
    </div >
  )
}

export default CareProfessionalCardSkeletonLoader