import React, { useState, useEffect } from "react"
import { Marker, InfoWindow, MarkerClusterer } from "@react-google-maps/api"
import { useMapContext } from "./Context"

import CareProfessionalCardInfoWindow from "../CareProfessionalCard/CareProfessionalCardInfoWindow"

const CareProfessionalLocations = () => {
  const { careProfessionals, viewingId, setViewingId, linkSecondary } =
    useMapContext()
  const [careProfIcon, setCareProfIcon] = useState(null)
  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  useEffect(() => {
    const careProfessionalIcon = {
      url:
        "data:image/svg+xml;charset=utf-8," +
        encodeURIComponent(
          `<svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 17.87 22"
            >
              <path fill="#006f8d" d="M8.93,21.5a1,1,0,0,1-.57-.18C7,20.34.5,15.22.5,9.35.5,4.3,4.12.5,8.93.5s8.44,3.8,8.44,8.85c0,5.86-6.52,11-7.83,12a1,1,0,0,1-.61.2Z"/>
              <path fill="#fff" d="M8.93,1c4.53,0,7.94,3.59,7.94,8.35,0,6-7.33,11.33-7.64,11.56a.53.53,0,0,1-.3.09h0a.52.52,0,0,1-.29-.09C8.33,20.68,1,15.35,1,9.35,1,4.59,4.41,1,8.93,1m0-1C3.84,0,0,4,0,9.35,0,15.46,6.69,20.71,8,21.7h0l0,0a1.5,1.5,0,0,0,.85.26,1.55,1.55,0,0,0,.86-.26h0l0,0c1.34-1,8-6.26,8-12.36C17.87,4,14,0,8.93,0Z"/>
              <path fill="#fff" d="M13.91,8.09A5.85,5.85,0,0,1,13,9.32a5.55,5.55,0,0,1-4,1.74,5.8,5.8,0,0,1-5-3,.34.34,0,0,1,0-.31,6.25,6.25,0,0,1,1.79-2,.45.45,0,0,1,.5,0,6.39,6.39,0,0,1,2.66,5.09h0a7.22,7.22,0,0,1,.39-2.06,6.23,6.23,0,0,1,2.28-3,.42.42,0,0,1,.5,0,5.89,5.89,0,0,1,1.78,2,.27.27,0,0,1,0,.31Z"/>
            </svg>`
        ),
      size: new window.google.maps.Size(38, 38),
      scaledSize: new window.google.maps.Size(38, 38),
      anchor: new window.google.maps.Point(19, 19),
      labelOrigin: new window.google.maps.Point(19, 19),
    }

    setCareProfIcon(careProfessionalIcon)
  }, [careProfessionals])

  return (
    <>
      <MarkerClusterer options={options} minimumClusterSize={3}>
        {clusterer =>
          careProfessionals.map((careProfessional, index) => {
            return careProfessional?.address?.lat &&
              careProfessional?.address?.lat ? (
              <div key={index}>
                <Marker
                  position={{
                    lat: parseFloat(careProfessional.address.lat.toFixed(7)),
                    lng: parseFloat(careProfessional.address.lng.toFixed(7)),
                  }}
                  icon={careProfIcon}
                  onClick={() =>
                    viewingId === careProfessional.id
                      ? setViewingId(null)
                      : setViewingId(careProfessional.id)
                  }
                  clusterer={clusterer}
                />
                {careProfessional.id === viewingId ? (
                  <InfoWindow
                    position={{
                      lat: parseFloat(careProfessional.address.lat.toFixed(7)),
                      lng: parseFloat(careProfessional.address.lng.toFixed(7)),
                    }}
                    onCloseClick={() => setViewingId(null)}
                  >
                    <a href={linkSecondary}>
                      <CareProfessionalCardInfoWindow
                        careProfessional={careProfessional}
                      />
                    </a>
                  </InfoWindow>
                ) : null}
              </div>
            ) : null
          })
        }
      </MarkerClusterer>
    </>
  )
}

export default CareProfessionalLocations
