import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import axios from "axios"

import Card from "../CareProfessionalCard/CareProfessionalCard"
import CardBasic from "../CareProfessionalCard/CareProfessionalCardBasic"
import CardSkeletonLoader from "../CareProfessionalCard/CareProfessionalCardSkeletonLoader"
import SearchMap from "./SearchMap"

import "./SearchMap.css"
import { MapProvider } from "./Context"

// Coordinates for central Australia
const DEFAULT_COORDINATES = { lat: -23.698, lng: 133.8807 }

const SearchMapContainer = ({
  search,
  headingPrimary,
  body,
  linkSecondary,
}) => {
  const searchHeader = useRef(null)
  const [mapBounds, setMapBounds] = useState(null)
  const [careProfessionals, setCareProfessionals] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [viewingId, setViewingId] = useState(null)
  const lat = search.lat
    ? parseFloat(parseFloat(search.lat).toFixed(7))
    : DEFAULT_COORDINATES.lat
  const lng = search.lng
    ? parseFloat(parseFloat(search.lng).toFixed(7))
    : DEFAULT_COORDINATES.lng

  useEffect(() => {
    makeRequest()
    /* eslint-disable-next-line */
  }, [mapBounds])

  const makeRequest = () => {
    if (!mapBounds) return
    const reqUrl =
      `${process.env.GATSBY_FRANKIE_SEARCH_URL}` +
      `?latitude=${lat}` +
      `&longitude=${lng}` +
      `&filter[bounds][north]=${mapBounds.north}` +
      `&filter[bounds][east]=${mapBounds.east}` +
      `&filter[bounds][south]=${mapBounds.south}` +
      `&filter[bounds][west]=${mapBounds.west}` +
      `&filter[bounds][buffer]=10`

    axios
      .get(reqUrl)
      .then(res => {
        setCareProfessionals(res.data.data)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="mb-8 lg:mb-12">
      <div>
        <div className="px-ogs pt-6" ref={searchHeader}>
          <div className="pb-4 md:pb-6">
            <h1 className="text-lg md:text-xl lg:text-2xl">{headingPrimary}</h1>
            <p className="max-w-50em pt-4 pb-6 text-xs font-normal sm:text-sm md:text-base">
              {body}
            </p>
            <p className="hidden text-xs font-normal sm:text-sm md:block md:text-base">
              There are{" "}
              <span className="font-semibold">
                {careProfessionals.length} Care Professionals
              </span>{" "}
              that match your query
            </p>
            <p className="block text-xs font-normal sm:text-sm md:hidden md:text-base">
              <span className="font-semibold">
                {careProfessionals.length} Care Professionals
              </span>{" "}
              match your query
            </p>
          </div>
        </div>
      </div>

      <div
        className="SearchMap-layout lg:pl-ogs"
        style={{
          "--search-header-height": `${
            searchHeader?.current?.clientHeight || 0
          }px`,
        }}
      >
        <div className="SearchMap-layout__header">
          <div className="hidden pr-4 pb-5 md:pb-6 lg:block">
            <div className="grid gap-y-2 md:gap-y-4">
              {!isLoading
                ? careProfessionals.map((careProfessional, index) => (
                    <a
                      href={linkSecondary}
                      className="col-span-1"
                      key={`${index}-care-professional`}
                      onMouseOver={() => setViewingId(careProfessional.id)}
                      onMouseLeave={() => setViewingId(null)}
                      onFocus={() => setViewingId(careProfessional.id)}
                    >
                      <Card careProfessional={careProfessional} />
                    </a>
                  ))
                : [...Array(6)].map((_, index) => (
                    <div className="col-span-1" key={`${index}-skeleton`}>
                      <CardSkeletonLoader ratio={[9, 16]} />
                    </div>
                  ))}

              <div className="col-span-1">
                <CardBasic />
              </div>
            </div>
          </div>
        </div>

        <MapProvider
          careProfessionals={careProfessionals}
          viewingId={viewingId}
          setViewingId={setViewingId}
          linkSecondary={linkSecondary}
        >
          <SearchMap
            setMapBounds={setMapBounds}
            setIsLoading={setIsLoading}
            lat={lat}
            lng={lng}
          />
        </MapProvider>
      </div>
    </div>
  )
}

SearchMapContainer.defaultProps = {
  headingPrimary: "Find A Care Professional near you",
}

SearchMapContainer.propTypes = {
  search: PropTypes.object.isRequired,
  headingPrimary: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export default SearchMapContainer
