import React from 'react'
import SearchMapContainer from '../SearchMap/SearchMapContainer'
import { Location } from '@reach/router'
import queryString from 'query-string'

const SearchResults = props => {
  return (
    <Location>
      {({ location, navigate }) => (
        <SearchMapContainer
          {...props}
          location={location}
          navigate={navigate}
          search={location.search ? queryString.parse(location.search) : {}}
        />
      )}

    </Location>
  )
}

export default SearchResults
