import React, { createContext, useContext } from 'react';

const MapContext = createContext({ careProfessionals: [] })

export const MapProvider = ({ children, careProfessionals, viewingId, setViewingId, linkSecondary }) => {

    return <MapContext.Provider value={{ careProfessionals, viewingId, setViewingId, linkSecondary }}>{children}</MapContext.Provider>
}

export const useMapContext = () => useContext(MapContext)
