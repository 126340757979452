import React, { useRef } from 'react'
import PropTypes from "prop-types"
import { GoogleMap } from '@react-google-maps/api'
import CareProfessionalLocations from './CareProfessionalLocations'
import { useGoogleApiContext } from "../GoogleApi/GoogleApiWrapper"

const SearchMap = ({ setMapBounds, setIsLoading, lat, lng }) => {
  const map = useRef(null)
  const { isLoaded } = useGoogleApiContext()

  // Used to change the map bounds when the map idles onto a specific location
  const onIdle = () => {
    setIsLoading(true)
    setMapBounds(map?.current?.state?.map?.getBounds()?.toJSON())
  }

  return (
    <div className="SearchMap-layout__map">
      <div className="SearchMap lg:sticky lg:top-0">
        {isLoaded ?
          <GoogleMap
            ref={map}
            mapContainerClassName="w-full h-full"
            onIdle={onIdle}
            center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
            zoom={15}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              styles: [
                {
                  featureType: 'poi',
                  elementType: 'labels',
                  stylers: [{ visibility: 'off' }],
                },
              ]
            }}
          >
            <CareProfessionalLocations />
          </GoogleMap> : null}
      </div>
    </div>
  )
}

SearchMap.propTypes = {
  setMapBounds: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
}

export default React.memo(SearchMap)
