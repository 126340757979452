import React from "react"
import LogoMark from "../SVG/Logomark"
import "./CareProfessionalCard.css"

const CardProfessionalCardBasic = () => {
  return (
    <div className="CardBasic space-y-4 px-4 py-6 md:space-y-6 md:px-6 md:py-8">
      <div className="flex justify-center">
        <div className="w-14 text-blue-500 md:w-18">
          <LogoMark />
        </div>
      </div>
      <div className="mx-auto max-w-xs text-center text-sm font-normal md:text-base">
        <p>
          Frankie is a new and growing business. We appreciate your patience as
          we are still developing our network of Care Professionals who can meet
          your needs.
        </p>
      </div>
    </div>
  )
}

export default CardProfessionalCardBasic
